// signout.js

const signOut = async () => {
  try {
    const response = await fetch("/api/users/signout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to sign out");
    }

    const data = await response.json();
    console.log(data.message); // Optional: Handle the response message

    // Optional: Redirect to the login page or home page after sign-out
    window.location.href = "/";
  } catch (error) {
    console.error("Error signing out:", error);
  }
};

export default signOut;
