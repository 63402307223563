import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import WalletIcon from "@mui/icons-material/Wallet";

const WagmiWallet = ({ user }) => {
  const [account, setAccount] = useState(null);
  const [provider, setProvider] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [check, setCheck] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(!!user);

  useEffect(() => {
    const initProvider = async () => {
      if (typeof window !== "undefined" && window.ethereum) {
        const ethersProvider = new ethers.BrowserProvider(window.ethereum);
        setProvider(ethersProvider);

        try {
          const signer = await ethersProvider.getSigner();
          const address = await signer.getAddress();
          setAccount(address);
          setIsConnected(true);
        } catch (error) {
          console.log("Failed to get account on load:", error);
        }
      } else {
        console.log("Please install MetaMask!");
      }
    };

    initProvider();
  }, []);

  const connectWallet = async () => {
    if (!provider) return;
    try {
      await provider.send("eth_requestAccounts", []);
      const signer = await provider.getSigner();
      const address = await signer.getAddress();
      setAccount(address);
      setIsConnected(true);
    } catch (error) {
      console.error("Failed to connect wallet:", error);
      setIsConnected(false);
    }
  };

  const disconnectWallet = () => {
    setAccount(null);
    setIsConnected(false);
    setCheck(false); // Reset check on disconnect
  };

  useEffect(() => {
    if (isConnected && isAuthenticated) {
      if (user && user.walletAddress === account) {
        console.log("correct wallet");
        setCheck(true);
      } else {
        alert("Connect to the right wallet");
        setCheck(false); // Reset check if wallet is wrong
      }
    }
  }, [account, isConnected, isAuthenticated, user]); // Update dependencies
  

  const showAddress = (str) => {
    if (str?.length <= 7) {
      return str;
    } else {
      const start = str?.substring(0, 6);
      const end = str?.substring(str.length - 4);
      return `${start}...${end}`;
    }
  };

  return (
    <div>
      {!isConnected ? (
        <button
          className="bg-orange-500 hover:text-white font-bold py-2 px-4 m-4 rounded-lg hover:bg-accent btn-grad"
          onClick={connectWallet}
        >
          <WalletIcon />
        </button>
      ) : (
        <button
          className="bg-orange-500 font-bold py-2 px-4 rounded-md text-white lg:my-4"
          onClick={disconnectWallet}
        >
          {check ? showAddress(account) : "Wrong wallet connected"}
        </button>
      )}
    </div>
  );
};

export default WagmiWallet;
